<template>
  <div class="tw-w-full tw-flex">
    <v-container style="max-width: 75rem" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8">
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 2vh">
    <Logo/>
  </div>
  <div style="width: 100%; align-items: center; justify-content: center; display: flex; margin-top: 5vh">
    <v-card  class="card" elevation="0">
      <p class="account-header">Create your account</p>
<!--      <v-btn rounded elevation="0"-->
<!--            class="mt-5" style="border: 1px solid #EAEAEA; color: #514A41; background-color: white; height: 42px">-->
<!--         <img src="@/assets/google.svg" alt="google" class="mr-3"/> SIGNUP WITH GOOGLE-->
<!--      </v-btn>-->
<!--      <div class="mt-8" style="align-items: flex-start; display: flex; flex-direction: row">-->
<!--        <v-divider/><p style="align-items: flex-start; margin-top: -1vh; margin-right: 1vh;-->
<!--        margin-left: 1vh">OR</p><v-divider/>-->
<!--      </div>-->
      <ValidationObserver v-slot="{ validate, invalid }" @submit.prevent="validate().then(handleRegister)">
      <div class="mt-5" style="flex-direction: row; display: flex; justify-content: space-between">
        <div style="display: flex; flex-direction: column; width: 48%" >
          <label class="mb-2 label">First Name</label>
          <v-text-field outlined  style="border-radius: 10px" color="#FF7A01" v-model="user.first_name" />
        </div>
        <div style="display: flex; flex-direction: column; width: 48%">
          <label class="mb-2 label">Last Name</label>
          <v-text-field outlined
                        style="border-radius: 10px" color="#FF7A01" v-model="user.last_name"
          />
        </div>
      </div>
      <div class="mt-4" style="display: flex; flex-direction: column; width: 100%">
        <label class="mb-2 label">Email</label>
        <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
        >
          <v-text-field
              class="my-1"
              name="emailAddress"
              v-model="user.email"
              height="44"
              outlined
              color="#FF7A01"
              style="border-radius: 10px"
          ></v-text-field>
          <span class="err">{{ errors[0] ? 'Must be a valid email' : '' }}</span>
        </ValidationProvider>
      </div>
      <div class="mt-4" style="display: flex; flex-direction: column; width: 100%">
        <label class="mb-2 label">Password</label>
        <ValidationProvider
            name="password"
            :rules="{
                  required: true,
                  regex:  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
                  min: 8
            }"
            v-slot="{ errors }"
        >
          <v-text-field
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' : 'password'"
              outlined
              v-model="user.password"
              height="44"
              style="border-radius: 10px"
              color="#FF7A01"
              id="password"></v-text-field>
          <span class="err">{{ errors[0] ? 'Password must contain at least one uppercase, lowercase, digit, special character and must be at least 8 character' : '' }}</span>
        </ValidationProvider>
      </div>
        <v-btn block class="mt-8" @click="handleRegister" :disabled="invalid" rounded elevation="0" color="#FF7A01" height="42" style="background-color: #FF7A01;
      color: white" :loading="loading">CREATE AN ACCOUNT</v-btn>
      </ValidationObserver>
      <div class="mt-2" style="display: flex; width: 100%; justify-content: center; height: auto; align-items: flex-start;">
        <v-btn  rounded text style="margin-bottom: 2vh; text-transform: none;" @click="loginPage">Have an account? Login</v-btn>
      </div>
    </v-card>
  </div>
</v-container>
  </div>
</template>

<script>
import Logo from "@/reuseable/Logo";
import {mapActions} from "vuex";
export default {
  name: "Signup",
  components:{
    Logo

  },
  data(){
    return{
      value : '',
      user:{
        first_name: '',
        last_name: '',
        email:'',
        password:''
      },
      loading : false,
      disable : false
    }
  },
  methods:{
    ...mapActions('onboarding', ['userRegister']),
    loginPage(){
      this.$router.push({name:'Login'});
    },
    handleRegister(){
      this.loading = true
      this.$store.dispatch('onboarding/userRegister', this.user).then(res =>{
        this.loading = false
        this.$router.push({name:'VerifyEmailPage',query:{email:this.user.email}})
        console.log(res.data)
        this.$displaySnackbar({success: true, message: "Your registration was successfully, we have sent you a verification email. "})

      })
          .catch(err => {
            this.loading = false;
            this.$displaySnackbar({success: false, message: "Your registration is not successful "})
            console.log(err);
          });
      console.log(this.user)
    },
    // handleEnableCreate(){
    //   if (this.user.last_name!==''&& this.user.first_name!==''&& this.user.email!==''&&this.user.password!==''){
    //     this.disable= false
    //   }
    //   else {
    //     this.disable = true
    //   }
    //   return this.disable
    // }

  },
  computed:{

  }
}
</script>

<style scoped lang="scss">
.card{
  display: flex;
  width: 482px;
  border: 1px solid #EAEAEA;
  border-radius: 32px;
  flex-direction: column;
  padding: 3% 3%;
  height: 85vh;
  box-sizing: border-box;


}
.account-header{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.04em;
  color: #292929;
  text-align: center;
  @media screen and (max-width: 768px){
    display: flex;
    width: 100%;
    justify-content: center;

  }
}
.err {
  display: block;
  color: red;
  font-size: 13px;
  margin-bottom: 5px;
}


</style>
